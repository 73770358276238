export const opera = {
  position: {
    lat: 48.14246794420783,
    lng: 17.111277527282912
  },
  title: 'Opera',
  description: `
    <div class="p-2 text-base">
      <div class="text-lg font-bold">OPERA</div>
      <div>Jesenského 4/C<br>Bratislava</div>
      <div class="mt-1"><a href="tel:+421948304937" class="text-primary font-bold">+421 948 304 937</a></div>
      <div class="mt-1"><a href="https://www.google.com/maps/search/?api=1&query=48.14246794420783,17.111277527282912&query_place_id=ChIJXV3RWkKJbEcR-71T36bG89w" target="_blank" class="text-primary font-bold">Navigovať ›</a></div>
    </div>`
}

export const motiv = {
  position: {
    lat: 48.165200092569016,
    lng: 17.159811714534875
  },
  title: 'Motiv',
  description: `
    <div class="p-2 text-base">
      <div class="text-lg font-bold">Motiv</div>
      <div>Trnavská cesta 67<br>Bratislava</div>
      <div class="mt-1"><a href="tel:+421948786676" class="text-primary font-bold">+421 948 786 676</a></div>
      <div class="mt-1"><a href="https://www.google.com/maps/search/?api=1&query=48.165200092569016,17.159811714534875&query_place_id=ChIJM9yIuNmObEcRjf55h2AgdMg" target="_blank" class="text-primary font-bold">Navigovať ›</a></div>
    </div>`
}

export const mamut = {
  position: {
    lat: 48.147516851175475,
    lng: 17.12040531340753
  },
  title: 'Mamut',
  description: `
    <div class="p-2 text-base">
      <div class="text-lg font-bold">Mamut</div>
      <div>Cintorínska 32<br>Bratislava</div>
      <div class="mt-1"><a href="tel:+421948318934" class="text-primary font-bold">+421 948 318 934</a></div>
      <div class="mt-1"><a href="https://www.google.com/maps/search/?api=1&query=48.147516851175475,17.12040531340753&query_place_id=ChIJY336GtaJbEcRiZWmjX09tXM" target="_blank" class="text-primary font-bold">Navigovať ›</a></div>
    </div>`
}
