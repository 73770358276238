class Modal {
  constructor(element) {
    this.element = element;
    this.dismiss = element.querySelector('[data-dismiss-modal]');

    this.registerEvents();
  }

  registerEvents() {
    this.dismiss.addEventListener('click', () => this.dismissModal());
  }

  dismissModal() {
    this.element.classList.add('hidden');
  }
}

export default Modal;
