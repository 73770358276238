class Tabs {
  constructor(element) {
    this.tabs = element.querySelectorAll('[data-tab]');
    this.panes = element.querySelectorAll('[data-pane]');

    this.registerEvents();
    this.showTab(this.tabs[0]);
  }

  registerEvents() {
    this.tabs.forEach(tab => {
      tab.addEventListener('click', () => {
        this.showTab(tab);
      });
    })
  }

  showTab(tab) {
    this.reset();

    tab.classList.add('bg-primary', 'text-primary');

    this.panes.forEach((pane) => {
      if (tab.dataset.tab === pane.dataset.pane) {
        pane.classList.remove('hidden');
      }
    })
  }

  reset() {
    this.tabs.forEach(tab => {
      tab.classList.remove('bg-primary', 'text-primary');
    });

    this.panes.forEach(pane => {
      pane.classList.add('hidden');
    })
  }
}

export default Tabs;
